.onollo-checkbox input {
  display: none;
  opacity: 0;
}

.onollo-checkbox {
  flex-shrink: 0;
  display: inline-block;
  width: 18px;
  height: 18px;
  border-radius: 4px;
  border: 1px solid var(--color-border);
  position: relative;
  cursor: pointer;
  background: #ffffff;
}

.onollo-checkbox:after {
  width: 6px;
  height: 6px;
  border-radius: 1px;
  background: var(--color-green);
  border: 0;
  position: absolute;
  left: 50%;
  top: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
}

.onollo-checkbox--checked:after {
  content: '';
}
.onollo-checkbox--disabled {
  opacity: 0.5;
  cursor: not-allowed;
}

.onollo-checkbox--theme-black {
  border-color: #000;
}

.onollo-checkbox--theme-black:after {
  width: calc(100% - 6px);
  height: calc(100% - 6px);
  background-color: #000;
}

.error-message {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  max-width: 1000px;
  padding: 10px 20px;
  position: fixed;
  top: 20px;
  left: 50%;
  -webkit-transform: translate(-50%, 0);
          transform: translate(-50%, 0);
  z-index: 9999;
  border-radius: 10px;
  border: 2px solid var(--color-danger);
  background: #fff;
  color: var(--color-danger);
  box-shadow: 0 0 10px 0 var(--color-light-danger);
  font-weight: 700;
}

.error-message > pre {
   white-space: pre-wrap;
}

.error-message button {
  color: var(--color-accent);
  font-size: 16px;
  font-weight: 500;
  margin-left: 5px;
  text-decoration: underline;
}

.error-message button:hover {
  text-decoration: none;
}

.error-message__clear-button {
  width: 20px;
  height: 20px;
  border: 0;
  outline: none;
  cursor: pointer;
  margin: 0 0 0 20px;
  position: relative;
}

.error-message__clear-button::after,
.error-message__clear-button::before {
  content: '';
  position: absolute;
  left: 0;
  width: 20px;
  height: 2px;
  border-radius: 2px;
  background: var(--color-danger);
}

.error-message__clear-button::after {
  -webkit-transform: rotate(45deg);
          transform: rotate(45deg);
}

.error-message__clear-button::before {
  -webkit-transform: rotate(-45deg);
          transform: rotate(-45deg);
}

@media (max-width: 1040px) {
  .error-message {
      max-width: calc(100vw - 40px);
  }
}

.async-select__menu .option__flex:hover .option__flex_right {
  text-indent: 5px;
}

.async-select__container {
  flex-grow: 2;
}

.async-select__option {
  word-break: break-word;
  height: auto !important;
  padding: 7px !important;
}

.base-select-dark__menu .select-filter__item > span {
  margin-left: 10px;
  margin-top: 2px;
  line-height: 1.1;
  text-align: left;
  word-break: break-all;
  white-space: normal;
}
.base-select-dark__menu .select-filter__item {
  padding: 4px;
  border-radius: 4px;
}
.base-select-dark__menu .select-filter__item:hover {
  background-color: var(--color-light-dark);
  color: #fff;
}
.base-select-dark__multi-value__label .onollo-checkbox {
  display: none;
}
.base-select-dark__multi-value__label .select-filter__item > span {
  margin-left: 0;
  padding: 0;
  box-sizing: border-box;
  line-height: 45px;
  font-size: 14px;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

.option__flex {
  display: flex;
  align-items: center;
}

.option__flex_left {
  flex-shrink: 0;
  font-size: 0;
  width: 40px;
  height: 40px;
  border: 1px solid #efefef;
  overflow: hidden;
  border-radius: 4px;
  margin: 0 7px 0 5px;
}

.option__flex_left img {
  width: 100%;
  height: 100%;
}

.option__flex_right {
  -webkit-transition: all 0.1s ease-in-out;
  transition: all 0.1s ease-in-out;
  margin-left: 19px;
}

.option__flex_right span {
  display: block;
  margin-top: 2px;
  font: 500 13px 'AvenirNextCyr', sans-serif;
  color: #949da0;
}

.option__flex_left + .option__flex_right {
  margin-left: 0;
}

#root .fill-grey-select__control {
  border: none;
  min-height: auto;
  background: #f4f4f4;
  border-radius: 100px;
  padding: 5px 10px;
  flex-wrap: nowrap;
}

#root .fill-grey-select__value-container {
  flex-shrink: 0;
  padding: 0;
  flex: none;
  min-width: calc(100% - 18px);
  position: static;
  display: block;
}

#root .fill-grey-select__value-container input {
  position: absolute;
}

#root .fill-grey-select__single-value {
  margin: 0;
  max-width: none;
  font: 400 15px AvenirNext;
  color: #000;
  position: static;
  -webkit-transform: translateY(0);
          transform: translateY(0);
}

#root .fill-grey-select__indicator {
  padding: 0;
  background: none;
  position: static;
  margin-left: 10px;
}

#root .fill-grey-select__indicator:after {
  content: '';
  display: block;
  border-top: 3px solid #000;
  border-left: 3px solid transparent;
  border-right: 3px solid transparent;
}

#root .fill-grey-select__loading-indicator {
  display: none !important;
}

#root .fill-grey-select__indicator svg {
  display: none;
}

#root .fill-grey-select__menu .onollo-select__option-with-checkbox-name {
  color: #000;
  margin-left: 0;
  width: 100%;
  font-size: 14px;
}

#root .fill-grey-select__option {
  font: 400 15px AvenirNext;
  color: #000;
  background-color: #f4f4f4;
  padding: 6px 10px;
  height: auto;
}

#root .fill-grey-select__option:active {
  background-color: #f4f4f4;
}

#root .fill-grey-select__option--is-selected {
  background-color: #f4f4f4;
}

@media (max-width: 480px) {
  .async-select__placeholder,
  .async-select__single-value,
  .async-select__menu {
    flex-wrap: nowrap;
    font-size: 14px !important;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  .async-select__value-container {
    flex-wrap: nowrap !important;
  }
}

.spyFeed_spy-feed__filters__hn--4 {
  display: flex;
  align-items: center;
  margin-bottom: 22px;
}

.spyFeed_spy-feed__socials__24Rl4 {
  display: flex;
  margin-left: 31px;
}

.spyFeed_spy-feed__social__3Rzvw {
  font-size: 0;
  width: 36px;
  height: 36px;
  border-radius: 50%;
}

.spyFeed_spy-feed__social__3Rzvw:not(:last-of-type) {
  margin-right: 15px;
}

.spyFeed_spy-feed__social_active__3Gl-9 {
  border: 1px solid #000;
}

.spyFeed_spy-feed__social__3Rzvw img {
  width: 30px;
  height: 30px;
}

.spyFeed_spy-feed__filters-select-wrp__xJAo- {
  min-width: 151px;
}

.spyFeed_spy-feed__post__1oArL {
  margin-bottom: 40px;
  -webkit-animation: spyFeed_fadeIn__1JlEv 1s ease-in-out;
          animation: spyFeed_fadeIn__1JlEv 1s ease-in-out;
}

.spyFeed_spy-feed__list__3CsDy {
  position: relative;
}

.spyFeed_spy-feed__list_loading__2nLGl:before {
  content: '';
  display: block;
  width: 100%;
  height: 58px;
  position: absolute;
  top: 0;
  left: 0;
  -webkit-transform: translateY(-100%);
          transform: translateY(-100%);
}

.spyFeed_spy-feed__list-items__3Sj2g {
  overflow: hidden;
  position: relative;
}

.spyFeed_spy-feed__progress__3ixxW .onollo__loader {
  height: auto !important;
}

.spyFeed_spy-feed__progress__3ixxW {
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  z-index: 99;
  background-color: #fff;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 76px 0;
}

.spyFeed_spy-feed__progress-title__7hK9_ {
  color: #000;
  margin-bottom: 12px;
}

.spyFeed_spy-feed__actions__sk63P {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  position: relative;
}

.spyFeed_spy-feed__actions__sk63P .spyFeed_spy-feed__progress__3ixxW {
  -webkit-transform: translateY(100%);
          transform: translateY(100%);
}

.spyFeed_spy-feed__action__2z9N- {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  box-shadow: 0 0 2px rgb(0 0 0 / 20%);
  margin: 5px 0;
}

.spyFeed_spy-feed__action__2z9N-:disabled {
  cursor: no-drop;
  opacity: 0.7;
}

.spyFeed_spy-feed__action-load-icon__2dgOr {
  -webkit-animation: spyFeed_rotation__20tVm 1.5s infinite linear;
          animation: spyFeed_rotation__20tVm 1.5s infinite linear;
}

.spyFeed_spy-feed__day-wrp__3YKpS {
  text-align: center;
  position: relative;
  margin-bottom: 20px;
}

.spyFeed_spy-feed__day-wrp__3YKpS:before {
  content: '';
  display: block;
  width: 100%;
  height: 1px;
  background-color: #dbdbdb;
  position: absolute;
  top: 50%;
  left: 0;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
}

.spyFeed_spy-feed__day__1B_Vg {
  display: inline-block;
  font: 400 16px 'AvenirNext', sans-serif;
  background-color: #fdfdfd;
  padding: 5px 10px;
  color: #797979;
  position: relative;
  z-index: 3;
}

@-webkit-keyframes spyFeed_rotation__20tVm {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(359deg);
            transform: rotate(359deg);
  }
}

@keyframes spyFeed_rotation__20tVm {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(359deg);
            transform: rotate(359deg);
  }
}

@-webkit-keyframes spyFeed_fadeIn__1JlEv {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes spyFeed_fadeIn__1JlEv {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@media (max-width: 480px) {
  .spyFeed_spy-feed__filters__hn--4 {
    flex-direction: column;
    align-items: flex-start;
  }

  .spyFeed_spy-feed__socials__24Rl4 {
    margin: 15px 0 0;
  }

  .spyFeed_spy-feed__filters-select-wrp__xJAo- {
    min-width: 100%;
  }

  .spyFeed_spy-feed__list_loading__2nLGl:before {
    height: 103px;
  }
}

@supports not (--test: #fff) {
  .btn--success {
    background-color: #2aad5b;
  }
  .btn--danger {
    background-color: #ff5661;
  }
  .btn--gray {
    color: #2b3847;
  }
  .btn--accent {
    background: #41b0bf;
  }
}
.btn {
  display: flex;
  align-items: center;
  justify-content: center;
  font: 600 15px/52px 'Work Sans', sans-serif;
  text-align: center;
  color: #ffffff;
  padding: 0 24px;
  height: 40px;
  border-radius: 40px;
  border: none;
  cursor: pointer;
  -webkit-transition: opacity 0.5s;
  transition: opacity 0.5s;
  text-decoration: none;
}

.btn .onollo__loader {
  height: 100%;
}

.btn:hover {
  opacity: 0.75;
}

.btn--alert {
  display: inline-flex;
}

.btn--wide {
  min-width: 150px;
}

.btn--success {
  background-color: var(--color-green);
}
.btn--danger {
  background-color: var(--color-danger);
}

.btn--gray {
  color: var(--color-blue);
  background-color: rgba(56, 65, 68, 0.05);
}

.btn--black,
.btn--black-transparent {
  font-size: 15px;
  height: 40px !important;
  line-height: 1;
  font-weight: 600;
}

.btn--black {
  background: #000;
}

.btn--black-transparent {
  color: #000;
  background-color: transparent;
}

.btn--black-border,
.btn--lightGreen,
.btn--yellow {
  background-color: #fff;
  color: #000;
  border: 1px solid #000;
  padding: 10px 35px;
  font: 600 15px/25px 'AvenirNext', sans-serif;
  min-width: 150px;
}

.btn--black-border.btn--small {
  font: 500 16px 'AvenirNext', sans-serif;
}

.btn--accent {
  background: var(--color-accent);
  color: #ffffff;
}

.btn--transparent {
  border: solid 1px #ffffff;
  color: #ffffff;
  background: transparent;
}
.btn--transparent:hover {
  color: #445b64;
  background: #ffffff;
  opacity: 1;
}
.btn--transparentBorder {
  border: solid 1px var(--color-dark) !important;
  color: var(--color-dark);
  background: rgba(0, 0, 0, 0);
}

.btn--transparentWithoutBorder {
  border: none;
  color: var(--color-dark);
  background: rgba(0, 0, 0, 0);
}

.btn--social {
  min-width: 270px;
  padding: 0 35px 0 18px;
  flex-shrink: 0;
  font-size: 15px;
  color: #262626;
}
.btn--store {
  color: #384144;
  line-height: 1.1;
}

.btn--add {
  padding: 0 24px;
}

.btn--add i {
  display: block;
  width: 32px;
  height: 32px;
  background-image: url(/static/media/plus.c9d80e45.svg);
  background-repeat: no-repeat;
  padding-right: 14px;
  margin-top: 14px;
}

.btn--social i,
.btn--store i {
  display: block;
  width: 32px;
  height: 32px;
  border-radius: 50%;
  background-image: url(/static/media/sprite.ddc592d4.svg);
  background-repeat: no-repeat;
  margin-right: 14px;
}

.btn--social span {
  width: 77%;
}

.btn--store i {
  background-size: contain;
  background-position: center;
  border-radius: 0;
}

.btn--social-youtube {
  background: rgba(255, 38, 1, 0.05);
  border: 1px #e80900 solid;
}
.btn--social-youtube i {
  background-image: url(/static/media/youtube_rect.c947b22a.svg);
  background-size: contain;
  background-position: center;
  border-radius: 0;
}

.btn--social-pinterest {
  background: #cb2027;
  color: #ffffff;
}
.btn--social-pinterest i {
  background-image: url(/static/media/pinterest-white.8f7ab189.svg);
  background-size: 100%;
  background-position: 0 0;
}

.btn--social-facebook {
  background: #4267b2;
  color: #ffffff;
}
.btn--social-facebook i {
  background: url(/static/media/facebook-white.661f9744.svg) center center no-repeat;
}

.btn--social-facebook-ig {
  background: #ffffff;
  color: #000000;
  border: 1px black solid;
}
.btn--social-facebook-ig i {
  width: 69px;
  border-radius: 0;
  background: url(/static/media/fb-ig.fc140c92.svg) center center no-repeat;
}

.btn--social-twitter {
  background: rgba(29, 161, 243, 0.05);
  border: 1px solid #1da1f3;
}
.btn--social-twitter i {
  background-position: -86px -194px;
}

.btn--social-linkedin {
  background: #0077b5;
  color: #ffffff;
}
.btn--social-linkedin i {
  background: url(/static/media/linkedin-white.d93afcde.svg) center center no-repeat;
}

.btn--social-twitter.btn--small i {
  background-position: -355px -355px;
}

.btn--social-instagram {
  background: rgba(145, 58, 174, 0.05);
}
.btn--social-instagram i {
  background-position: -142px -194px;
}

.btn--social-instagram {
  background: rgba(145, 58, 174, 0.05);
}

.btn--social-tiktok {
  background: rgb(105,201,208);
  background: -webkit-linear-gradient(left, rgba(105,201,208,1) 0%, rgba(0,0,0,1) 50%, rgba(238,29,82,1) 100%);
  background: linear-gradient(90deg, rgba(105,201,208,1) 0%, rgba(0,0,0,1) 50%, rgba(238,29,82,1) 100%);
  border: 1px solid #010101;
  color: #fff;
}
.btn--social-tiktok i {
  background-image: url(/static/media/tiktok.1a4681a2.svg);
  background-size: 100%;
  background-position: 0 0;
}

.btn--store-shopify {
  background-color: rgba(149, 191, 71, 0.3);
}
.btn--store-shopify i {
  background-image: url(/static/media/shopify.da520736.svg);
}
.btn--store-magento {
  background-color: rgba(243, 134, 87, 0.25);
}
.btn--store-magento i {
  background-image: url(/static/media/magento.655c15d1.svg);
}

.btn--store-woocommerce {
  background-color: rgba(150, 88, 138, 0.25);
}
.btn--store-woocommerce i {
  background-image: url(/static/media/woo.64237cb9.svg);
}

.btn--store-bigcommerce {
  background-color: rgba(13, 82, 255, 0.25);
}
.btn--store-bigcommerce i {
  background-image: url(/static/media/bigcommerce.06e0f070.svg);
}

.btn--store-wix {
  background-color: rgb(156, 187, 229);
}
.btn--store-wix i {
  background-image: url(/static/media/wix-logo.a683e684.svg);
}

.btn--store-wish {
  background-color: rgb(189, 209, 238);
}
.btn--store-wish i {
  background-image: url(/static/media/wish-logo.a2719f7f.svg);
}

.btn--crazy {
  background-image: -webkit-linear-gradient(292deg, #f15b2d, #f15b2d 14%, #f47b27 76%, #f47b29);
  background-image: linear-gradient(158deg, #f15b2d, #f15b2d 14%, #f47b27 76%, #f47b29);
}

.btn--disabled,
.btn--disabled:hover {
  opacity: 0.3;
  cursor: not-allowed;
}

.btn--loading {
  position: relative;
  pointer-events: none;
  z-index: 1;
}

.onollo__loader--btn {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 2;
  background-color: #fff;
}

.btn--small {
  height: auto;
  padding: 10px 15px;
  line-height: 20px;
}

.btn--small i {
  flex-shrink: 0;
  width: 20px;
  height: 20px;
}

.btn--small.btn--social-facebook-ig i {
  width: 41px;
}

.btn--main-small {
  height: auto;
  line-height: normal;
  font-weight: normal;
  border-radius: 5px;
  padding: 7px 10px;
  font-size: 14px;
  cursor: pointer;
  border: 0;
  -webkit-transition: all 0.25s ease;
  transition: all 0.25s ease;
}

.btn--success.btn--hover-accent {
  color: var(--color-blue);
  background-color: #f5f8f8;
}

.btn--success.btn--hover-accent:hover {
  opacity: 1;
  background-color: var(--color-green);
  color: #fff;
}

.albumName__field .btn--main-small,
.post_modal__textarea .btn--main-small {
  margin: 2px 4px;
  display: inline-block;
  padding: 3px 8px 2px 8px;
  background-color: #f6f8f8;
  border-radius: 18px;
  font-size: 13px;
  text-align: center;
  text-transform: lowercase;
  cursor: pointer;
  -ms-user-select: none;
      user-select: none;
  -moz-user-select: none;
  -khtml-user-select: none;
  -webkit-user-select: none;
  -o-user-select: none;
}

.albumName__field .btn--success.btn--hover-accent.selected,
.albumName__field .btn--success.btn--hover-accent:hover,
.post_modal__textarea .btn--success.btn--hover-accent.selected,
.post_modal__textarea .btn--success.btn--hover-accent:hover {
  background-color: #424446;
  color: #fff;
}

.btn--lightGreen {
  border: none;
  background-color: #589885;
  color: #fff;
}

.btn--yellow {
  border: none;
  background-color: #f7cb90;
  color: #000;
}


.btn--lock,
.btn--locked {
  position: relative;
}

.btn--lock:before,
.btn--locked:before {
  content: '';
  width: 100%;
  height: 100%;
  background-color: rgba(255, 255, 255, 0.5);
  position: absolute;
  left: 0;
  top: 0;
  z-index: 2;
}

.btn--locked:after {
  content: '';
  display: block;
  width: 9px;
  height: 15px;
  background: url(/static/media/lock.82e83c18.svg) center no-repeat;
  position: absolute;
  left: 10px;
  bottom: 10px;
  z-index: 3;
}

.btn--lock:after {
  content: '';
  display: block;
  width: 9px;
  height: 15px;
  background: url(/static/media/lock.82e83c18.svg) center no-repeat;
  position: absolute;
  left: 15px;
  bottom: 12px;
  z-index: 3;
}

.btn--whiteFill {
  background-color: #fff;
  color: #000;
  border: 1px solid #000;
}

.btn--blue {
  background-color: #3033a9;
  color: #fff;
}

.btn--darkBlue {
  background-color: #1d1831;
  color: #fff;
  height: auto;
}

.btn--red-border {
  background-color: #fff;
  color: #ff0000;
  border: 1px solid #ff0000;
  padding: 10px 21px;
  height: auto;
  font: 500 16px 'AvenirNext', sans-serif;
  min-width: 150px;
}

.btn--narrow {
  min-width: auto;
  line-height: 10px !important;
}

.btn--bitly {
  border: 1px solid var(--color-content-border);
}

.btn--bitly i {
  background-size: 100%;
  background-image: url(/static/media/bitly.6d6f0927.svg);
}

.btn--blackWithWhiteBorder {
  height: auto;
  font: 500 16px/19px 'Work Sans', sans-serif;
  border: 1px solid #fff;
  background-color: #000;
  padding: 6px 18px;
}

.onollo__loader {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
}

.onollo__loader img {
  display: block;
}

.upgradePlanPopover_upgrade-plan__1Ju4O {
  width: 277px;
  background: #ffffff;
  box-shadow: 0px 4px 20px rgba(134, 134, 134, 0.25);
  border-radius: 5px;
  padding: 20px 17px 10px;
  text-align: center;
  font-family: 'AvenirNext', sans-serif;
  color: #000;
}

.upgradePlanPopover_upgrade-plan__title__153LY {
  font: 600 16px 'AvenirNext', sans-serif;
  margin-bottom: 10px;
}

.upgradePlanPopover_upgrade-plan__desc__auEpx {
  font: 400 13px/27px 'AvenirNext', sans-serif;
  margin-bottom: 20px;
}

.upgradePlanPopover_upgrade-plan__actions__oQMgv .btn {
  width: 100%;
}

.upgrade-plan-popover {
  padding: 0 !important;
  margin: 0 !important;
  opacity: 0 !important;
  background: none !important;
  border: none !important;
  z-index: 1001 !important;
}

.upgrade-plan-popover:before,
.upgrade-plan-popover:after {
  display: none;
}

.upgrade-plan-popover.show {
  opacity: 1 !important;
}

.upgradePlanModal_upgrade-plan__text__2WJk8 {
  text-align: center;
  font: 400 16px/27px 'AvenirNext', sans-serif;
}

.upgradePlanModal_upgrade-plan__text__2WJk8 a {
  text-decoration: underline;
}

.upgradePlanModal_upgrade-plan__text__2WJk8 a:hover {
  text-decoration: none;
}

.upgradePlanModal_upgrade-plan__actions__25JmQ {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 30px;
}

.upgradePlanModal_upgrade-plan__actions__25JmQ > .btn:last-child {
  text-decoration: underline;
}

.timer_timer__1GGzs {
  display: flex;
  justify-content: center;
}

.timer_time_unit-wrapper__3hhIO {
  text-align: center;
  position: relative;
}

.timer_time_unit__10eDB {
  background-color: #F1F1F1;
  border-radius: 8px;
  padding: 40px 20px;
  margin: 0 10px;
  font-size: 40px;
  font-weight: 600;
  width: 80px;
}

.timer_time_unit-label__2hvQs {
  font-size: 16px;
  margin-top: 5px;
  text-align: center;
}
.freePlanExtinctionModal_header__2vVM4 {
  font-size: 32px !important;
  margin-top: 20px;
}

.freePlanExtinctionModal_upgrade-plan__33wGP {
  padding-top: 20px;
  position: relative;
}

.freePlanExtinctionModal_upgrade-plan__33wGP::before {
  content: url(/static/media/dino.b0885b46.svg);
  position: absolute;
  top: 0;
}

.freePlanExtinctionModal_upgrade-plan__text__3m2VY {
  text-align: center;
  font: 400 16px/27px 'AvenirNext', sans-serif;
}

.freePlanExtinctionModal_upgrade-plan__text__3m2VY a {
  text-decoration: underline;
}

.freePlanExtinctionModal_upgrade-plan__text__3m2VY a:hover {
  text-decoration: none;
}

.freePlanExtinctionModal_upgrade-plan__actions__bVJ8G {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 30px;
}

.freePlanExtinctionModal_upgrade-plan__actions__bVJ8G > .btn:last-child {
  text-decoration: underline;
}

.freePlanExtinctionModal_free-plan__accent__xHaYK {
  color: #ED7200;
}

.freePlanExtinctionModal_starter-button__accent__2PuQ- {
  color: #589885;
}
.promoPlanModal_bfm__3ZSLk {
  overflow: hidden;
}

.promoPlanModal_bfm__flex__1pI7E {
  display: flex;
}

.promoPlanModal_bfm__left__NNr1D {
  width: 50%;
  background: #1c182f;
  color: #fff;
}

.promoPlanModal_bfm__right__mLokh {
  width: 50%;
  padding: 50px 82px 39px;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  color: #1d1831;
  position: relative;
}

.promoPlanModal_bfm__close__1s0vW {
  font-size: 0;
  position: absolute;
  right: 12px;
  top: 12px;
}

.promoPlanModal_bfm__logo__3ndlw {
  position: relative;
  margin: -25px 0;
}

.promoPlanModal_bfm__logo-info__3cVTL {
  position: absolute;
  left: 50%;
  top: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  text-align: center;
}

.promoPlanModal_bfm__logo-title__1T4N- {
  font: 600 14px/17px 'Work Sans', sans-serif;
  text-shadow: 0px 0px 5.40798px #cea5ff;
  letter-spacing: 0.1em;
  margin-bottom: 7px;
}

.promoPlanModal_bfm__logo-desc__3xT4n {
  font: 400 10px/11px 'Work Sans', sans-serif;
  color: #fcfcfc;
}

.promoPlanModal_bfm__features-wrp__4GSut {
  padding: 0 74px 64px;
}

.promoPlanModal_bfm__features-title__zyaIi {
  font: 600 20px/20px 'Work Sans', sans-serif;
  margin-bottom: 17px;
}

.promoPlanModal_bfm__features__1N5_h {
  padding-left: 14px;
  margin-left: 5px;
}

.promoPlanModal_bfm__feature__3n5_I {
  list-style-type: disc;
  font-size: 14px;
}

.promoPlanModal_bfm__feature_disc-plus__2xlsM {
  list-style-type: none;
  position: relative;
}

.promoPlanModal_bfm__feature_disc-plus__2xlsM:before {
  content: '+';
  display: block;
  position: absolute;
  top: 50%;
  left: -16px;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
}

.promoPlanModal_bfm__feature__3n5_I:not(:last-child) {
  margin-bottom: 13px;
}

.promoPlanModal_bfm__period__1R08T {
  font-size: 18.1123px;
  margin-bottom: 5px;
}

.promoPlanModal_bfm__name__3pT1_ {
  font: 600 32px/38px 'Work Sans', sans-serif;
  background-image: -webkit-linear-gradient(0.45deg, #5b0eff 0.29%, #cf0eff 103.47%);
  background-image: linear-gradient(89.55deg, #5b0eff 0.29%, #cf0eff 103.47%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  -moz-background-clip: text;
  -moz-text-fill-color: transparent;
}

.promoPlanModal_bfm__desc__1D2fp {
  font: 400 16px/25px 'Work Sans', sans-serif;
  margin: 40px 0;
}

.promoPlanModal_bfm__prices__3cRhU {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 30px;
}

.promoPlanModal_bfm__prices-label__3N-K9 {
  font: 400 17.44px/20px 'Work Sans', sans-serif;
  margin-bottom: 26px;
}

.promoPlanModal_bfm__prices-past__1Q21p {
  font: 500 22px/26px 'Work Sans', sans-serif;
  letter-spacing: 0.05em;
  text-decoration: line-through;
}

.promoPlanModal_bfm__prices-current__3ifRd {
  font: 600 40px/39px 'Work Sans', sans-serif;
}

.promoPlanModal_bfm__btn__1cmUw {
  min-width: 120px;
}

.promoPlanModal_bfm__date__1i9yj {
  font: 600 12px/14px 'Work Sans', sans-serif;
  margin-top: 10px;
}

.promoPlanModal_bfm_theme_christmas__30aKw .promoPlanModal_bfm__logo__3ndlw {
  margin: 30px auto 0;
  text-align: center;
}

.promoPlanModal_bfm_theme_christmas__30aKw .promoPlanModal_bfm__logo-info__3cVTL {
  display: none;
}

.promoPlanModal_bfm_theme_christmas__30aKw .promoPlanModal_bfm__left__NNr1D {
  background-color: #00261b;
}

.promoPlanModal_bfm_theme_christmas__30aKw .promoPlanModal_bfm__right__mLokh {
  padding: 50px 54px 39px;
}

.promoPlanModal_bfm_theme_christmas__30aKw .promoPlanModal_bfm__prices-current__3ifRd {
  color: #ff0000;
}

.promoPlanModal_bfm_theme_christmas__30aKw .promoPlanModal_bfm__name__3pT1_ {
  background-image: -webkit-linear-gradient(0.45deg, #ff0000 0.29%, #209f54 103.47%);
  background-image: linear-gradient(89.55deg, #ff0000 0.29%, #209f54 103.47%);
}

.promoPlanModal_bfm_theme_christmas__30aKw .promoPlanModal_bfm__btn__1cmUw {
  background-color: #00261b;
}

@media (max-width: 750px) {
  .promoPlanModal_bfm__flex__1pI7E {
    flex-direction: column;
  }

  .promoPlanModal_bfm__left__NNr1D,
  .promoPlanModal_bfm__right__mLokh {
    width: 100%;
  }

  .promoPlanModal_bfm__right__mLokh {
    padding: 30px 40px;
  }

  .promoPlanModal_bfm__logo__3ndlw {
    text-align: center;
  }

  .promoPlanModal_bfm__features-wrp__4GSut {
    padding: 0 40px 30px;
  }

  .promoPlanModal_bfm_theme_christmas__30aKw .promoPlanModal_bfm__logo__3ndlw {
    margin-top: 15px;
  }
}

.upgradePlanSelectStoreModal_select-stores__text__1US2v {
  text-align: center;
  font: 400 16px/27px 'AvenirNext', sans-serif;
}

.upgradePlanSelectStoreModal_select-stores__list__cR9lo {
  border: 1px solid #000;
  border-radius: 8px;
  padding: 15px;
  height: 150px;
  margin-top: 15px;
  overflow-y: auto;
}

.upgradePlanSelectStoreModal_select-stores__item__1xXSw {
  padding: 12px 15px;
  border: 1px solid #000;
  border-radius: 8px;
  width: 100%;
  -webkit-transition: all 0.25s ease;
  transition: all 0.25s ease;
  margin-bottom: 10px;
}

.upgradePlanSelectStoreModal_select-stores__item_selected__3X3Xo {
  color: #fff;
  background-color: #589885;
  border-color: #589885;
}

.upgradePlanSelectStoreModal_select-stores__loader__1Liz6 {
  width: 100%;
  height: 100%;
}

.cloudSelectModal_cloud__3WhxA {
  color: #000;
  -webkit-filter: drop-shadow(0px 4px 20px rgba(0, 0, 0, 0.25));
          filter: drop-shadow(0px 4px 20px rgba(0, 0, 0, 0.25));
  border-radius: 10px;
  overflow: hidden;
}

.cloudSelectModal_cloud__flex__2ruWF {
  display: flex;
}

.cloudSelectModal_cloud__left__1DAgq {
  flex-shrink: 0;
  width: 130px;
  background-color: #f2f4f4;
  padding: 19px 30px;
}

.cloudSelectModal_cloud__right__1ii17 {
  flex-grow: 1;
  position: relative;
  overflow: hidden;
}

.cloudSelectModal_cloud__logo__3EH9O {
  display: flex;
  align-items: center;
  margin-bottom: 67px;
}

.cloudSelectModal_cloud__logo-img__3dwr9 {
  width: 30px;
  height: 30px;
}

.cloudSelectModal_cloud__logo-text__28tx7 {
  margin-left: 10px;
  font-size: 24px;
}

.cloudSelectModal_cloud__nav__3ROoH {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.cloudSelectModal_cloud__close-btn__2taEc {
  position: absolute;
  right: 15px;
  top: 15px;
  z-index: 3;
}

.cloudSelectModal_cloud__nav-item__29ujd {
  font-size: 14px;
  margin-bottom: 34px;
  color: #000;
}

.cloudSelectModal_cloud__nav-item_active__ltvH9 {
  font-weight: bold;
}

.cloudSelectModal_cloud__list-wrp__24CGj {
  padding: 0 50px 25px;
  min-height: 631px;
  max-height: 631px;
  overflow-y: auto;
  position: relative;
}

.cloudSelectModal_cloud__list-wrp__24CGj::-webkit-scrollbar {
  display: inherit;
}

.cloudSelectModal_cloud__list-wrp__24CGj::-webkit-scrollbar {
  border-radius: 6px;
  width: 10px;
  background-color: #f6f5fc;
}

.cloudSelectModal_cloud__list-wrp__24CGj::-webkit-scrollbar-track {
  border-radius: 6px;
  background-color: #f6f5fc;
}

.cloudSelectModal_cloud__list-wrp__24CGj::-webkit-scrollbar-thumb {
  border-radius: 6px;
  background-color: #e4eaed;
}

.cloudSelectModal_cloud__title-wrp__Rz1V5 {
  padding: 25px 50px 43px;
}

.cloudSelectModal_cloud__title__2_Nlr {
  font: 600 24px/28px 'Work Sans', sans-serif;
  margin-bottom: 15px;
}

.cloudSelectModal_cloud__count__fXUun {
  font: 400 13px/15px 'Work Sans', sans-serif;
  font-style: italic;
}

.cloudSelectModal_cloud__list__2FIjE {
  display: flex;
  flex-wrap: wrap;
  margin: 0 -10px;
}

.cloudSelectModal_cloud__media__3B6Qq {
  width: 115px;
  height: 115px;
  margin: 0 10px 20px;
  border: 1px solid transparent;
  display: flex;
  overflow: hidden;
  border-radius: 5px;
  -webkit-transition: all 0.25s ease-in-out;
  transition: all 0.25s ease-in-out;
}

.cloudSelectModal_cloud__media_mode_selected__3RH6L {
  border-color: #589885;
}

.cloudSelectModal_cloud__media_mode_view__1m0iq {
  width: 100%;
  height: 250px;
}

.cloudSelectModal_cloud__media_mode_view__1m0iq:not(.cloudSelectModal_cloud__media_mode_selected__3RH6L) {
  border-color: #d6d6d6;
}

.cloudSelectModal_cloud__media_mode_view__1m0iq .cloudSelectModal_cloud__media-info__2jVkx {
  z-index: 3;
  flex-grow: 1;
  position: static;
  -webkit-animation: 0.4s csm_fadeIn ease-in-out;
          animation: 0.4s csm_fadeIn ease-in-out;
  -webkit-animation-fill-mode: forwards;
          animation-fill-mode: forwards;
  -webkit-animation-delay: 0.25s;
          animation-delay: 0.25s;
}

.cloudSelectModal_cloud__media_mode_view__1m0iq .cloudSelectModal_cloud__media-thumb__3Qucm {
  flex-shrink: 0;
  width: 50%;
  -webkit-transition: all 0.4s ease-in-out;
  transition: all 0.4s ease-in-out;
}

.cloudSelectModal_cloud__media-thumb__3Qucm {
  height: 100%;
  width: 100%;
  position: relative;
  overflow: hidden;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  background-color: #f6f6f6;
}

.cloudSelectModal_cloud__media-check__1U98t {
  width: 16px;
  height: 15px;
  position: absolute;
  left: 6px;
  top: 6px;
  z-index: 3;
  border-radius: 50%;
  border: 1px solid rgba(255, 255, 255, 0.8);
  box-shadow: 0px 0px 5px rgb(0 0 0 / 20%);
}

.cloudSelectModal_cloud__media-check_checked__PWwNC {
  background-color: #bcd6ce;
  border-color: #589885;
  background-image: url(/static/media/green-check.d5d51a57.svg);
  background-repeat: no-repeat;
  background-position: center;
}

.cloudSelectModal_cloud__media-view-box__2Xj6M {
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
  z-index: -1;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  opacity: 0;
  -webkit-transition: all 0.25s ease-in-out;
  transition: all 0.25s ease-in-out;
}

.cloudSelectModal_cloud__media-overlay__3EqII {
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
  z-index: 1;
}

.cloudSelectModal_cloud__media-thumb__3Qucm:hover .cloudSelectModal_cloud__media-view-box__2Xj6M {
  z-index: 2;
  opacity: 1;
}

.cloudSelectModal_cloud__media-view-btn__2rWgl {
  border: 1px solid #fff;
  border-radius: 10px;
  padding: 2px 20px;
  font: 500 12px/14px 'Work Sans', sans-serif;
  color: #fff;
  position: relative;
  z-index: 2;
}

.cloudSelectModal_cloud__actions__FVO_L {
  box-shadow: 0px -2px 8px rgba(0, 0, 0, 0.15);
  background-color: #fff;
  padding: 15px 50px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-weight: 500;
  position: relative;
}

.cloudSelectModal_cloud__selected-info__3l-eA span {
  margin-right: 11px;
}

.cloudSelectModal_cloud__media-info__2jVkx {
  position: relative;
  z-index: -1;
  opacity: 0;
  position: absolute;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  -webkit-transition: 0.25s ease-in-out;
  transition: 0.25s ease-in-out;
}

.cloudSelectModal_cloud__media-info-content__1lh6H {
  flex-grow: 1;
  overflow-y: auto;
  padding: 27px 53px;
  word-break: break-word;
}

.cloudSelectModal_cloud__media-info-title__2xfsj {
  font-weight: 500;
  margin-bottom: 10px;
}

.cloudSelectModal_cloud__media-info-text__1AZ2J {
  font-size: 12px;
  line-height: 14px;
}

.cloudSelectModal_cloud__media-info-footer__3EXTg {
  display: flex;
  padding: 13px 17px;
  box-shadow: 0px -2px 8px rgba(0, 0, 0, 0.15);
}

.cloudSelectModal_cloud__media-info-close__1I2eJ {
  margin-left: auto;
  display: flex;
  align-items: center;
  color: #909090;
  font-size: 12px;
}

.cloudSelectModal_cloud__media-info-close__1I2eJ img {
  margin: -2px 5px 0 0;
}

.cloudSelectModal_cloud__action-add__3HLJG {
  height: auto;
  line-height: 1;
  padding: 13px 30px;
}

.cloudSelectModal_cloud__media-video__9c67d {
  position: absolute;
  left: 0;
  top: 0;
  z-index: 1;
  width: 100%;
  height: 100%;
}

.cloudSelectModal_cloud__list-loader__1CF4w {
  position: absolute;
  left: 50%;
  top: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
}

@-webkit-keyframes csm_fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 100%;
  }
}

@keyframes csm_fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 100%;
  }
}

@media (max-width: 830px) {
  .cloudSelectModal_cloud__3WhxA {
    margin: 0 15px;
  }

  .cloudSelectModal_cloud__flex__2ruWF {
    flex-direction: column;
  }

  .cloudSelectModal_cloud__left__1DAgq {
    width: 100%;
    padding: 15px 30px 15px 15px;
  }

  .cloudSelectModal_cloud__logo__3EH9O {
    margin-bottom: 20px;
  }

  .cloudSelectModal_cloud__nav__3ROoH {
    flex-direction: row;
  }

  .cloudSelectModal_cloud__nav-item__29ujd {
    margin: 0 15px 0 0;
  }

  .cloudSelectModal_cloud__title-wrp__Rz1V5 {
    padding: 15px 15px 20px;
  }

  .cloudSelectModal_cloud__list-wrp__24CGj {
    min-height: 300px;
    max-height: 300px;
  }

  .cloudSelectModal_cloud__list-wrp__24CGj {
    padding: 0 15px 20px;
  }

  .cloudSelectModal_cloud__actions__FVO_L {
    padding: 15px;
    flex-direction: column;
    align-items: flex-start;
  }

  .cloudSelectModal_cloud__selected-info__3l-eA {
    margin-bottom: 15px;
    align-self: center;
  }

  .cloudSelectModal_cloud__action-add__3HLJG {
    width: 100%;
  }

  .cloudSelectModal_cloud__media-info-content__1lh6H {
    padding: 15px;
  }
}

.contentFilter_wrapper__3J9ej {
	margin-bottom: 20px;
}

.contentFilter_radio_btn__ql1gF {
  display: inline-block;
}

.contentFilter_radio_btn__ql1gF input[type=radio] {
	display: none;
}
.contentFilter_radio_btn__ql1gF label {
	display: inline-block;
  	color: #000;
	cursor: pointer;
	font-size: 14px;
	padding: 5px 20px;
	line-height: 34px;
	border-radius: 8px;
	-webkit-user-select: none;
	   -moz-user-select: none;
	    -ms-user-select: none;
	        user-select: none;
}
 
.contentFilter_radio_btn__ql1gF input[type=radio]:checked + label {
	background: #ececec;
	color: #000;
  	font-weight: 900;
}

.contentFilter_radio_btn__ql1gF label:hover {
	color: #666;
}

:root {
  --base-background: rgba(253, 253, 253, 1);
  --grey-background: #f3f3f3;
  --test: #fff;
  --color-content: #445b64;
  --color-content-light: rgba(122, 149, 164, 1);
  --color-dark: #384144;
  --color-dark-gray: rgb(53, 58, 55);
  --color-light-dark: #4f5e63;
  --color-light-accent: rgba(65, 176, 191, 0.5);
  --color-accent: #41b0bf;
  --color-accent-pink: #ee7d7a;
  --color-light-green: rgba(45, 170, 90, 0.5);
  --color-green: #2aad5b;
  --color-light-danger: rgba(255, 86, 97, 0.5);
  --color-danger: #ff5661;
  --color-warning: #b99918;
  --color-blue: #2b3847;

  --color-facebook: #4267b2;
  --color-twitter: #1da1f2;
  --color-linkedin: #197fb7;
  --color-instagram: radial-gradient(
    circle at 30% 107%,
    #fdf497 0%,
    #fdf497 5%,
    #fd5949 45%,
    #d6249f 60%,
    #285aeb 90%
  );
  --color-pinterest: #bd081c;

  --color-light-attention: rgb(254, 231, 163);
  --color-attention: rgb(153, 120, 85);

  --color-border: #c3cad2;
  --color-border--active: #8997a7;
  --color-content-border: #e4eaed;
  --color-border-grey: #e5eaed;
  --color-border-main: #000;

  --max-width: 100%;
  --min-width: 100%;
  --default-form-width: 524px;

  --twitter-main-color: #1da1f3;
  --facebook-main-color: #4267b2;
  --pinterest-main-color: #bd191d;

  --color-base: #4e5a6a;
  --color-base-light: #b1b9c2;
  --color-link: #41b0bf;
}



.visible-md,
.visible-sm,
.visible-xs {
    display: none;
}
.visible-lg {
    display: block;
}

@media screen and(max-width: 959px) {

    .hidden-md {
        display: none !important;
    }
    .visible-md {
        display: block;
    }
    .center {
        max-width: 90%;
    }
    .md-center {
        max-width: 768px;
    }
    .md-center100p {
        max-width: 100%;
    }

}

@media screen and(max-width: 767px) {

    .hidden-sm {
        display: none !important;
    }
    .visible-sm {
        display: block;
    }
    .sm-center {
        max-width: 480px;
    }
    .sm-center100p {
        max-width: 100%;
    }

}

@media screen and(max-width: 479px) {

    .hidden-xs {
        display: none !important;
    }
    .visible-xs {
        display: block;
    }
    .xs-center100p {
        max-width: 100%;
    }

}
/*
    if variables not supported write original values
*/
@supports not (--test: #fff) {
  body {
    min-width: 980px;
    color: #445b64;
    background: rgba(253, 253, 253, 1);
  }

  ::-moz-selection {
    background: #445b64;
  }

  ::selection {
    background: #445b64;
  }

  .center {
    max-width: 1210px;
    min-width: 980px;
  }
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: 'Work Sans', sans-serif !important;
}

body.dragging,
body.dragging * {
  cursor: -webkit-grabbing !important;
  cursor: grabbing !important;
}

body {
  position: relative;
  font: 400 17px/18px 'Work Sans', sans-serif;
  color: var(--color-content);
  -webkit-text-size-adjust: none;
  -webkit-overflow-scrolling: touch;
  -ms-overflow-style: none;
  background: var(--base-background);
}

body::-webkit-scrollbar-thumb {
  background-color: #e4eaed;
  border-radius: 6px;
}

body::-webkit-scrollbar {
  width: 16px;
  background-color: #f6f5fc;
}

a {
  color: inherit;
  text-decoration: none;
}

a:hover {
  cursor: pointer;
  text-decoration: none;
}

.link {
  text-decoration: underline;
  cursor: pointer;
}

.link:hover {
  text-decoration: none;
}

:focus,
:active {
  outline: none;
}

::-moz-selection {
  color: #fff;
  background: var(--color-content);
}

::selection {
  color: #fff;
  background: var(--color-content);
}

img {
  border: 0;
  position: relative;
  vertical-align: top;
  max-width: 100%;
}

li {
  list-style: none;
  position: relative;
}

.center {
  margin: 0 auto;
  max-width: var(--max-width);
  min-width: var(--min-width);
  padding: 0 20px;
  box-sizing: border-box;
  position: relative;
}

/*
-----------------------------------------------------------------------------*/
.clr:after {
  clear: both;
  content: '';
  display: table;
}

.pull-left {
  float: left;
}

.pull-right {
  float: right;
}

.align-center {
  text-align: center;
}

.align-right {
  text-align: right;
}

.notsel {
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
}

.h1 {
  font-size: 52px;
  line-height: 62px;
  font-weight: bold;
}

.h2 {
  font: 700 42px/49px 'Work Sans', sans-serif;
  color: var(--color-dark);
}

.h3 {
  font-size: 32px;
  line-height: 38px;
  font-weight: bold;
}

.h4 {
  font-weight: bold;
  font-size: 21px;
}

hr {
  height: 1px;
  border: 0;
  background: rgba(137, 151, 167, 0.1);
}

.text_normal {
  font-weight: 400;
}

main,
header,
footer,
section,
article,
aside,
time {
  display: block;
  position: relative;
}

.item_new,
.item_beta {
  flex-shrink: 0;
  display: inline-block;
  position: relative;
}

.item_new::after,
.item_beta::after {
  top: -20px;
  right: -25px;
  position: absolute;
  border-radius: 10px;
  padding: 0 4px;
  text-align: center;
  background-color: rgba(238, 125, 122, 0.5);
  font-size: 10px;
  font-weight: 500;
  line-height: 1.5;
  pointer-events: none;
}

.item_new::after {
  content: 'new';
}

.item_beta::after {
  content: 'beta';
}

/* form
-----------------------------------------------------------------------------*/

.checkbox {
  width: 18px;
  height: 18px;
  position: relative;
}

.checkbox i {
  display: inline-block;
  width: 18px;
  height: 18px;
  position: relative;
  top: 0;
  left: 0;
  background: url(/static/media/sprite.ddc592d4.svg) no-repeat -30px -101px;
}

.checkbox.check i {
  background: url(/static/media/sprite.ddc592d4.svg) no-repeat -78px -101px;
}

.checkbox input {
  opacity: 0;
  position: absolute;
  left: 9999px;
}

.double-field--wrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

@media screen and (max-width: 479px) {
  .double-field--wrapper {
    flex-direction: column;
  }
}

.double-field--item {
  margin-top: 20px;
}

.double-field--item .react-datepicker-wrapper,
.double-field--item .react-datepicker__input-container {
  width: 100%;
}

.double-field--item,
.double-field--wrapper .select2-container {
  width: calc(50% - 10px);
}

@media (max-width: 479px) {
  .double-field--item,
  .double-field--wrapper .select2-container {
    width: 100%;
  }
}

/* flex
-----------------------------------------------------------------------------*/
.flexbox {
  display: flex;
}

.flexbox--center {
  justify-content: center;
}

.flexbox_wrap {
  flex-wrap: wrap;
}
.flexbox--justify-between {
  justify-content: space-between;
}

.flexbox--align-center {
  align-items: center;
}
.store-buttons {
  flex-direction: column;
}

.store-buttons button {
  min-width: 270px;
}

.store-buttons button span {
  width: 100%;
}

/* width
-----------------------------------------------------------------------------*/
.w50p {
  width: 50%;
}

.w100p {
  width: 100%;
}

/* hidden
-----------------------------------------------------------------------------*/
.hide {
  display: none !important;
}

.hidden {
  overflow: hidden;
}

.hidden-visible {
  position: absolute;
  top: 0;
  left: 0;
  opacity: 0;
  width: 0;
  height: 0;
  z-index: -1;
  overflow: hidden;
}

button {
  border: none;
  background: none;
  padding: 0;
  cursor: pointer;
}

main {
  overflow: hidden;
}

/* margin
-----------------------------------------------------------------------------*/
.mt-20 {
  margin-top: 20px;
}

.mr0 {
  margin-right: 0;
}

@supports not (--test: #fff) {
  .modal__info {
    color: #445b64;
  }
}

.modal__content {
  padding: 0 66px;
}

.modal__content_theme_dark {
  padding: 50px 45px;
  color: #000;
}

.modal__content_theme_dark .h2 {
  padding: 0 !important;
  margin-bottom: 29px !important;
  text-align: center;
  margin-bottom: 8px;
  font: 700 22px/30px 'AvenirNext';
}

.modal__content_theme_dark .h2 {
  display: flex;
  align-items: center;
  justify-content: center;
}

.modal__content_theme_dark .h2 img {
  flex-shrink: 0;
  margin-left: 25px;
}

.modal__content_theme_dark .modal__actions {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 40px;
}

.modal__content_theme_dark .modal__actions .btn:not(:last-child) {
  margin-right: 20px;
}

.modal__content .h2,
.onollo__modal .h2 {
  padding: 0 15px;
  text-align: center;
  word-break: break-word;
}

.onollo__modal_flex {
  display: flex;
  align-items: flex-start;
  flex-wrap: wrap;
  padding: 30px 30px 0;
}

.onollo__modal_flex > form,
.onollo__modal_flex__left {
  padding: 0 50px 0 0;
  width: 1px;
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
}

.onollo__modal .onollo__modal_flex__left_top {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}

.onollo__modal_flex > form,
.onollo__modal_flex__right {
  width: 50%;
  max-width: 560px;
}

.onollo__modal_flex__left_bottom {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  width: 100%;
}

.onollo__modal_flex__left_bottom .input__wrapper {
  width: calc(100% - 96px);
  margin-bottom: 20px;
  text-align: left;
}

.onollo__modal_flex__left_bottom .post_modal__textarea_wrp {
  margin: 0 0 20px;
}

.onollo__modal_flex > form input,
.onollo__modal_flex__left input {
  margin: 0;
}

.onollo__modal_flex .modal__manipulation {
  width: 100%;
  margin-top: 40px;
  padding-top: 40px;
  border-top: 1px solid #c3cad2;
  justify-content: flex-end;
}

.onollo__modal_flex .modal__schedule_wrp .btn {
  width: 200px;
}

.onollo__modal_flex .modal__schedule_wrp .btn span {
  white-space: nowrap;
}

.onollo__modal_flex .modal__schedule_wrp + button {
  width: 200px !important;
  flex-grow: 0;
}

.modal__info {
  color: var(--color-content);
  max-width: 750px;
  margin: 5px auto 0;
  text-align: center;
  line-height: 23px;
  font-family: 'Work Sans', sans-serif;
  font-size: 15px;
}

.modal__info-large {
  line-height: 1.3;
  font-size: 24px;
  font-weight: 600;
}

.modal__manipulation {
  display: flex;
  justify-content: flex-end;
  margin-top: 30px;
  border-top: 1px solid #d7dfe3;
  padding: 24px 66px 0 66px;
}
.modal__manipulation_center {
  justify-content: center;
}

.modal__manipulation > button:nth-child(1),
.modal__manipulationInner > button:nth-child(1) {
  flex-shrink: 0;
}

.modal__manipulation > button:nth-child(2),
.modal__manipulationInner > button:nth-child(2) {
  margin-left: 20px;
}

.modal__manipulation--single-btn button:nth-child(1) {
  width: 100%;
}

.modal__schedule_btn {
  width: 100%;
  margin-top: 20px;
}

.modal__schedule_wrp {
  flex-grow: 0;
  position: relative;
}

.modal__schedule_wrp {
  flex-grow: 0;
  position: relative;
}

.modal__manipulationInner {
  display: flex;
  justify-content: flex-end;
}

.modal__schedule_wrp + button {
  width: auto !important;
  flex-grow: 2;
}

.modal__schedule_wrp .modal__schedule_btn {
  margin: 0;
  width: 200px;
  background: var(--color-blue);
}

.modal__schedule_wrp .modal__scheduled_btn {
  padding-right: 40px;
}

.modal__manipulation .modal__schedule_cancel {
  margin: 0;
  background: none;
  border: none;
  font-size: 0;
  cursor: pointer;
  background-image: url("data:image/svg+xml,%3Csvg version='1.1' width='19px' height='21px' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 15.642 15.642' xmlns:xlink='http://www.w3.org/1999/xlink' enable-background='new 0 0 15.642 15.642'%3E%3Cpath fill='%23fff' fill-rule='evenodd' d='M8.882,7.821l6.541-6.541c0.293-0.293,0.293-0.768,0-1.061 c-0.293-0.293-0.768-0.293-1.061,0L7.821,6.76L1.28,0.22c-0.293-0.293-0.768-0.293-1.061,0c-0.293,0.293-0.293,0.768,0,1.061 l6.541,6.541L0.22,14.362c-0.293,0.293-0.293,0.768,0,1.061c0.147,0.146,0.338,0.22,0.53,0.22s0.384-0.073,0.53-0.22l6.541-6.541 l6.541,6.541c0.147,0.146,0.338,0.22,0.53,0.22c0.192,0,0.384-0.073,0.53-0.22c0.293-0.293,0.293-0.768,0-1.061L8.882,7.821z'/%3E%3C/svg%3E%0A");
  background-repeat: no-repeat;
  background-position: center;
  width: 40px;
  height: 100%;
  position: absolute;
  right: 0;
  top: 50%;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
  -webkit-transition: opacity 0.5s;
  transition: opacity 0.5s;
}

.modal__manipulation .modal__schedule_cancel:disabled {
  cursor: no-drop;
}

.modal__manipulation .modal__schedule_cancel:not(:disabled):hover {
  opacity: 0.5;
}

.onollo__modal_title__flex {
  border-bottom: 1px solid #c3cad2;
  padding-bottom: 30px;
  margin: 0 30px;
  position: relative;
  margin-bottom: 5px;
}

.onollo__modal_title__flex .btn__how_item_works {
  position: absolute;
  bottom: 33px;
  left: 15px;
}

.ol_with-number {
  list-style: decimal;
  text-align: left;
}
.ol_with-number li {
  list-style-type: decimal;
}
.ol_with-number b {
  font-weight: bold;
}
.ol_with-number em {
  font-style: italic;
}
.copy {
  cursor: pointer;
}
.copy:hover {
  opacity: 0.9;
}
.copy::after {
  content: '';
  display: inline-block;
  width: 10px;
  height: 15px;
  background-image: url(/static/media/copy.5cead73b.svg);
  background-repeat: no-repeat;
  background-size: contain;
  opacity: 0.85;
}

@media (max-width: 1020px) {
  .onollo__modal_flex {
    flex-direction: column;
  }

  .onollo__modal_flex__left,
  .onollo__modal_flex__right {
    width: 100%;
  }

  .onollo__modal_flex__right {
    max-width: 100%;
    margin-top: 30px;
  }

  .onollo__modal_title__flex .btn__how_item_works {
    display: none;
  }
}

@media (max-width: 526px) {
  .modal__manipulation,
  .modal__manipulationInner {
    width: 100%;
    flex-direction: column-reverse;
  }
  body .onollo__modal_flex .modal__schedule_wrp .btn,
  body .modal__manipulation .btn,
  .add-account__btns .btn {
    width: 100% !important;
  }
  .modal__manipulation > button:nth-child(2),
  .modal__manipulationInner > button:nth-child(2) {
    margin: 0 0 20px;
  }

  .onollo__modal_flex__left_bottom .input__wrapper {
    width: calc(100% - 39px);
  }
}

@media (max-width: 480px) {
  .onollo__modal_flex {
    padding: 15px;
  }
  .modal__content,
  .add-account__wrapper {
    padding: 25px !important;
  }
  .modal__content .h2 {
    font: 700 35px/33px 'Work Sans', sans-serif;
  }
  .ol_with-number b {
    word-break: break-all;
  }
}

@supports not (height: 100vh) {
  .page {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: rgba(253, 253, 253, 1);
  }
}

@supports not (--test: #fff) {
  .page {
    background: rgba(253, 253, 253, 1);
  }
}

.page {
  min-height: 100vh;
  background: var(--base-background);
}

.page_text-color_black {
  color: #000;
}

.page > article {
  padding-top: 51px;
  padding-bottom: 40px;
}

.page-flex {
  display: flex;
}

.page-flex__right {
  flex-grow: 1;
  padding: 32px;
}

.page_default-font-color_black {
  color: #000;
}

@media (max-width: 780px) {
  .page-flex {
    flex-direction: column;
  }
}

.toggle-button__wrapper {
  position: absolute;
  top: 22px;
  right: 0;
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.toggle-button__wrapper > button {
  border: 0;
  outline: none;
  margin-left: 20px;
  color: var(--color-blue);
  cursor: pointer;
  font-weight: bold;
  font-size: 15px;
  -webkit-transition: color ease-in-out 0.3s;
  transition: color ease-in-out 0.3s;
  background: transparent;
}

.toggle-button__wrapper > button:hover {
  color: var(--color-accent);
}

.tr-link {
  cursor: pointer;
}

.tr-link:hover {
  background-color: var(--color-content-border);
}

.Toastify__toast-container--bottom-left {
  bottom: 100px;
}

.container {
  padding: 30px 78px;
}

@media (max-width: 1100px) {
  .container {
    padding: 30px;
  }
}

@media (max-width: 768px) {
  .container {
    padding: 30px 15px;
  }
}

.page-with-sidebar {
    display: flex;
    min-height: calc(100vh - 85px);
    background-color: #fff;
    color: #000;
}

.page-with-sidebar__sidebar {
    background-color: #F9FAFA;
    width: 180px;
    min-width: 180px;
    padding: 30px;
}

.page-with-sidebar__content {
    padding: 0 20px;
}

.sidebar, .sidebar__nav {
    display: block;
}

.sidebar__item:not(:last-child) {
    margin-bottom: 32px;
}

.sidebar__link {
    font-size: 15px;
}

.page-with-sidebar__title {
    margin-top: 20px;
    font-weight: 600;
    font-size: 22px;
    line-height: 30px;
}

@media (max-width: 966px) {
    .page-with-sidebar {
        flex-wrap: wrap;
    }
    .page-with-sidebar__sidebar {
        background-color: #fff;
        width: 100%;
        border-bottom: 1px solid #D6D6D6;
        padding: 30px 0 20px
    }
    .sidebar__nav {
        display: flex;
        overflow-y: hidden;
        overflow-x: auto;
        padding-bottom: 10px;
    }
    .sidebar__nav:after {
        content: '';
        padding-left: 1px;
    }
    .sidebar__item {
        margin-bottom: 0!important;
        margin-right: 20px;
    }
    .sidebar__item:first-child {
        margin-left: 20px;
    }
    .sidebar__link {
        white-space: nowrap;
    }
}
.field-error {
  width: 100%;
  text-align: right;
  margin-top: 5px;
  font: 600 11px 'AvenirNext', sans-serif;
  color: var(--color-danger);
}

.tooltip {
    background: #ffffff;
    border-radius: 5px;
    padding: 7px 5px;
    box-shadow: 0 0 24px rgba(37,38,94, 0.1);
}

.tooltip_template {
    margin-left: 0 !important;
    max-width: 250px;
    max-height: 150px;
    word-break: break-all;
    overflow-x: auto;
}
.tooltip_template li {
    list-style: inherit;
    list-style-type: inherit;
}

.tooltip_template ul {
    list-style-type: disc;
    list-style-position: inside
}

.tooltip_template ol {
    list-style-type: decimal;
    list-style-position: inside
}
.tooltip_template i{
    display: inline-block;
    margin-bottom: 1px;
    background-color: #eee;
    padding: 2px 2px 0 2px;
    border-radius: 4px;
    font-weight: 500;
    text-transform: uppercase;
}
.tooltip_template i+i{
    margin-left: 2px;
}




@font-face {
  font-family: 'Work Sans';
  src: url(/static/media/worksans-regular.91c0cda7.woff2) format('woff2'),
    url(/static/media/worksans-regular.042aa1f7.woff) format('woff');
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Work Sans';
  src: url(/static/media/worksans-medium.066f5de4.woff2) format('woff2'),
    url(/static/media/worksans-medium.ae5a68b2.woff) format('woff');
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Work Sans';
  src: url(/static/media/worksans-semibold.df007940.woff2) format('woff2'),
    url(/static/media/worksans-semibold.dcabaaf3.woff) format('woff');
  font-weight: 600;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Work Sans';
  src: url(/static/media/worksans-bold.f34c32bd.woff2) format('woff2'),
    url(/static/media/worksans-bold.ab6adbbd.woff) format('woff');
  font-weight: 700;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'AvenirNext';
  src: url(/static/media/AvenirNext-Regular.6e3e4b07.woff2) format('woff2'),
    url(/static/media/AvenirNext-Regular.291d62b8.woff) format('woff');
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'AvenirNext';
  src: url(/static/media/AvenirNext-Bold.10e9293e.woff2) format('woff2'),
    url(/static/media/AvenirNext-Bold.e7ce4ad1.woff) format('woff');
  font-weight: 700;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'AvenirNextCyr';
  src: url(/static/media/AvenirNextCyr-Medium.58488618.woff2) format('woff2'),
    url(/static/media/AvenirNextCyr-Medium.f73c47b3.woff) format('woff');
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'AvenirNextCyr';
  src: url(/static/media/AvenirNextCyr-Demi.39591f8e.woff2) format('woff2'),
    url(/static/media/AvenirNextCyr-Demi.23c3d002.woff) format('woff');
  font-weight: 600;
  font-style: normal;
  font-display: swap;
}

.account-tabs{
    margin-top: 25px;
}

.account-tabs__list{
    display: flex;
    justify-content: flex-start;
    flex-shrink: 0;
}

.no-account{
    display: flex;
    align-items: center;
    justify-content: flex-start;
}

.no-account__message{
    width: 100%;
    text-align: center;
    color: var(--color-dark);
}

.accordion-list {
  width: 100%;
  border-radius: 6px;
}

.accordion-item {
  border: 1px solid var(--color-border);
  margin-bottom: 10px;
  border-radius: 6px;
}

.accordion-item.accordion-item__without-body {
  border: 1px solid var(--color-content-border);
}

.accordion-item--hidden {
  border: 1px solid var(--color-content-border);
}

.accordion-item--hidden:not(.accordion-item__without-body):hover {
  border: 1px solid var(--color-border);
}

.accordion-title {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  border-bottom: 1px solid var(--color-border);
  cursor: pointer;
}
.accordion-item__without-body .accordion-title {
  border-bottom: 0;
  cursor: auto;
  cursor: initial;
}
.accordion-title--closed {
  border-bottom: 0;
}

.accordion-item__without-body .accordion-title--closed {
  min-height: 55px;
}

.accordion-body {
  padding: 20px;
}

[data-star] {
  text-align: left;
  font-style: normal;
  font-size: 20px;
  display: inline-block;
  position: relative;
  unicode-bidi: bidi-override;
}

[data-star]::before { 
  display: block;
  content: '\2605\2605\2605\2605\2605';
  color: #ddd;
}

[data-star]::after {
  white-space: nowrap;
  position: absolute;
  top: 0;
  left: 0;
  content: '\2605\2605\2605\2605\2605';
  width: 0;
  color: #FF8970;
  overflow: hidden;
  height: 100%;
}

[data-star^="0.1"]::after,[data-star^=".1"]::after{width:2%}
[data-star^="0.2"]::after,[data-star^=".2"]::after{width:4%}
[data-star^="0.3"]::after,[data-star^=".3"]::after{width:6%}
[data-star^="0.4"]::after,[data-star^=".4"]::after{width:8%}
[data-star^="0.5"]::after,[data-star^=".5"]::after{width:10%}
[data-star^="0.6"]::after,[data-star^=".6"]::after{width:12%}
[data-star^="0.7"]::after,[data-star^=".7"]::after{width:14%}
[data-star^="0.8"]::after,[data-star^=".8"]::after{width:16%}
[data-star^="0.9"]::after,[data-star^=".9"]::after{width:18%}
[data-star^="1"]::after{width:20%}
[data-star^="1.1"]::after{width:22%}
[data-star^="1.2"]::after{width:24%}
[data-star^="1.3"]::after{width:26%}
[data-star^="1.4"]::after{width:28%}
[data-star^="1.5"]::after{width:30%}
[data-star^="1.6"]::after{width:32%}
[data-star^="1.7"]::after{width:34%}
[data-star^="1.8"]::after{width:36%}
[data-star^="1.9"]::after{width:38%}
[data-star^="2"]::after{width:40%}
[data-star^="2.1"]::after{width:42%}
[data-star^="2.2"]::after{width:44%}
[data-star^="2.3"]::after{width:46%}
[data-star^="2.4"]::after{width:48%}
[data-star^="2.5"]::after{width:50%}
[data-star^="2.6"]::after{width:52%}
[data-star^="2.7"]::after{width:54%}
[data-star^="2.8"]::after{width:56%}
[data-star^="2.9"]::after{width:58%}
[data-star^="3"]::after{width:60%}
[data-star^="3.1"]::after{width:62%}
[data-star^="3.2"]::after{width:64%}
[data-star^="3.3"]::after{width:66%}
[data-star^="3.4"]::after{width:68%}
[data-star^="3.5"]::after{width:70%}
[data-star^="3.6"]::after{width:72%}
[data-star^="3.7"]::after{width:74%}
[data-star^="3.8"]::after{width:76%}
[data-star^="3.9"]::after{width:78%}
[data-star^="4"]::after{width:80%}
[data-star^="4.1"]::after{width:82%}
[data-star^="4.2"]::after{width:84%}
[data-star^="4.3"]::after{width:86%}
[data-star^="4.4"]::after{width:88%}
[data-star^="4.5"]::after{width:90%}
[data-star^="4.6"]::after{width:92%}
[data-star^="4.7"]::after{width:94%}
[data-star^="4.8"]::after{width:96%}
[data-star^="4.9"]::after{width:98%}
[data-star^="5"]::after{width:100%}

.slick-slider .slick-arrow {
  z-index: 1;
  top: 50%;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
  background: url(/static/media/arrow-left.879f06a9.svg) no-repeat !important;
  width: 19px;
  height: 28px;
}

.slick-slider .slick-arrow:before {
  content: none;
}
.slick-slider .slick-prev {
  left: 6px;
}
.slick-slider .slick-next {
  right: 6px;
  -webkit-transform: translateY(-50%) rotate(180deg);
          transform: translateY(-50%) rotate(180deg);
}

.slick-slider .slick-dots {
  bottom: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.slick-slider .slick-dots li {
  width: 10px;
  height: 10px;
  margin: 0;
}
.slick-slider .slick-dots li button {
  width: 5px;
  height: 5px;
  padding: 5px;
}
.slick-slider .slick-dots li button:before {
  width: 5px;
  height: 5px;
  font-size: 6px;
  line-height: 10px;
  left: 2px;
  opacity: 1;
  color: var(--color-content-border);
}
.slick-slider .slick-dots li.slick-active button:before {
  color: var(--color-green);
}

.slick-slider .slick-slide img {
  border-radius: 5px;
}

.dropdown__filter-item{
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.dropdown__filter-item > span{
  margin-left: 10px;
}

/*
 * Container style
 */
 .ps {
  overflow: hidden !important;
  overflow-anchor: none;
  -ms-overflow-style: none;
  touch-action: auto;
  -ms-touch-action: auto;
}

/*
 * Scrollbar rail styles
 */
.ps__rail-x {
  display: none;
  transition: background-color .2s linear, opacity .2s linear;
  -webkit-transition: background-color .2s linear, opacity .2s linear;
  height: 15px;
  /* there must be 'bottom' or 'top' for ps__rail-x */
  bottom: 0px;
  /* please don't change 'position' */
  position: absolute;
}

.ps__rail-y {
  display: none;
  transition: background-color .2s linear, opacity .2s linear;
  -webkit-transition: background-color .2s linear, opacity .2s linear;
  width: 15px;
  /* there must be 'right' or 'left' for ps__rail-y */
  right: 0;
  /* please don't change 'position' */
  position: absolute;
}

.ps--active-x > .ps__rail-x,
.ps--active-y > .ps__rail-y {
  display: block;
  background-color: transparent;
}

/*
 * Scrollbar thumb styles
 */
.ps__thumb-x {
  background-color: var(--color-content-border);
  border-radius: 6px;
  transition: background-color .2s linear, height .2s ease-in-out;
  -webkit-transition: background-color .2s linear, height .2s ease-in-out;
  height: 6px;
  /* there must be 'bottom' for ps__thumb-x */
  bottom: 2px;
  /* please don't change 'position' */
  position: absolute;
}

.ps__thumb-y {
  background-color: var(--color-content-border);
  border-radius: 6px;
  transition: background-color .2s linear, width .2s ease-in-out;
  -webkit-transition: background-color .2s linear, width .2s ease-in-out;
  width: 6px;
  /* there must be 'right' for ps__thumb-y */
  right: 2px;
  /* please don't change 'position' */
  position: absolute;
}

.ps__rail-x:hover > .ps__thumb-x,
.ps__rail-x:focus > .ps__thumb-x,
.ps__rail-x.ps--clicking .ps__thumb-x {
  height: 11px;
}

/* MS supports */
@supports (-ms-overflow-style: none) {
  .ps {
    overflow: auto !important;
  }
}

@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
  .ps {
    overflow: auto !important;
  }
}
.scrollbar-container {
  position: relative;
  height: 100%;
  display: flex;
}

.scrollbar-container .post {
  height: 100%;
  display: flex;
  flex-direction: column;
  margin-right: 4px;
}

.scrollbar-container .post__content {
  flex-grow: 2;
  display: flex;
  flex-direction: column;
}
.scrollbar-container .post__content img {
  margin-top: auto;
}
/*# sourceMappingURL=styles.css.map*/

.Toastify__toast {
  border-radius: 5px;
}
.Toastify__toast_hide {
  display: none !important;
}
.Toastify__toast--success {
  background-color: var(--color-green);
}
.Toastify__toast--error {
  background-color: var(--color-danger);
}

.Toastify__toast--warn {
  background-color: var(--color-warning);
}
.Toastify__toast-body {
  text-align: center;
  font-weight: bold;
  font-size: 18px;
}

.Toastify__toast-container--bottom-left {
  left: 0;
  bottom: 0;
  padding: 0;
}

.sync-product-notification {
  margin-bottom: 0;
  min-height: auto;
  border-radius: 5px 5px 0 0;
  max-width: 455px;
}

.sync-product-notification .Toastify__toast-body {
  font-size: 16px;
  margin: 0;
  text-align: left;
}

#___reactour .reactour__helper {
  max-width: 360px;
  padding: 20px;
  background-color: #000;
  border-radius: 17px;
  color: #fff;
  font: 400 16px AvenirNext, sans-serif;
}

.reactour__helper h3 {
  font-size: 22px;
  font-weight: 500;
  margin-bottom: 6px;
  padding-right: 15px;
}

#___reactour > *:first-child {
  opacity: .3;
}

.reactour__helper > *:last-child:hover {
  color: #5e5e5e;
}

[data-tour-elem="badge"] {
  display: none;
}

#___reactour .reactour__dot {
  margin: 0;
  border: none;
  width: 9px;
  height: 9px;
  background-color: #fff;
}

#___reactour .reactour__dot:hover:before {
  opacity: 1;
}

#___reactour .reactour__dot:not(:last-of-type) {
  margin-right: 5px;
}

#___reactour .reactour__dot--is-active {
  background-color: #6EBB2A;
  -webkit-transform: scale(1);
          transform: scale(1);
}

#___reactour .reactour__dot--is-active:before {
  color: #6EBB2A;
}

[data-tour-elem="controls"] {
  display: flex;
}

[data-tour-elem="navigation"] {
  order: -1;
  margin-right: auto;
}

[data-tour-elem="left-arrow"] span,
[data-tour-elem="right-arrow"] span {
  width: 67px;
  display: flex;
  align-items: center;
  color: #fff;
  height: 32px;
  padding: 9px;
  border-radius: 40px;
}

#___reactour [data-tour-elem="left-arrow"]:hover,
#___reactour [data-tour-elem="right-arrow"]:hover {
  color: #fff;
}

[data-tour-elem="left-arrow"] span svg {
  -webkit-transform: rotate(-180deg);
          transform: rotate(-180deg);
  margin-right: 10px;
  fill: #6EBB2A;
}

[data-tour-elem="left-arrow"] span path {
  stroke: #6EBB2A;
}

#___reactour [data-tour-elem="left-arrow"] {
  margin-right: 0;
}

#___reactour [data-tour-elem="right-arrow"] {
  margin-left: 10px;
}

[data-tour-elem="right-arrow"] span {
  justify-content: center;
  background-color: #6EBB2A;
}

[data-tour-elem="right-arrow"] span svg {
  margin-left: 10px;
}
.datetime-picker {
  position: relative;
  border: none;
  background-color: var(--color-dark);
  border-radius: 4px;
  font-weight: 600;
  font-size: 15px;
  color: #4a4a4a;
  width: 250px;
  box-sizing: content-box;
  z-index: 100;
}

.datetime-picker .calendar {
  margin-top: 0;
  border-radius: 0;
  border: 0;
}
.datetime-picker .calendar .calendar-nav {
  display: flex;
  justify-content: space-between;
  border: 0;
  min-height: 32px;
  padding: 4px;
  border-radius: 0;
}
.datetime-picker .calendar .calendar-nav button {
  border: 0;
  width: 32px;
  height: 32px;
  border-radius: 4px;
  outline: none;
  cursor: pointer;
  background-color: transparent;
  background-image: url(/static/media/arrow-white.ab88e426.svg);
  background-position: center;
  background-repeat: no-repeat;
}
.datetime-picker .calendar .calendar-nav button.next-month {
  -webkit-transform: rotate(180deg);
          transform: rotate(180deg);
}
.datetime-picker .calendar .calendar-nav button:hover {
  background-color: var(--color-green);
}
.datetime-picker .calendar .calendar-nav .current-date {
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 4px;
  padding: 0 6px;
  color: #ffffff;
  cursor: pointer;
}
.datetime-picker .calendar .calendar-nav .current-date:hover {
  background-color: var(--color-green);
}
.datetime-picker .calendar .calendar-nav .current-date.disabled {
  cursor: default;
}
.datetime-picker .calendar table {
  display: block;
  margin: 4px;
}
.datetime-picker .calendar table th,
.datetime-picker .calendar table td {
  padding: 0;
}
.datetime-picker .calendar table thead {
  display: block;
  margin: 8px 0 3px;
}
.datetime-picker .calendar table thead tr {
  display: flex;
  flex-wrap: wrap;
}
.datetime-picker .calendar table thead th {
  color: rgba(255, 255, 255, 0.8);
  display: flex;
  align-items: center;
  justify-content: center;
  flex: 1 1;
  text-align: center;
  text-transform: uppercase;
  font-size: 0.8em;
  font-weight: 600;
}
.datetime-picker .calendar table tbody {
  display: block;
}
.datetime-picker .calendar table tbody tr {
  display: flex;
  flex-wrap: wrap;
}
.datetime-picker .calendar table tbody tr td {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 32px;
  border-radius: 4px;
  color: #ffffff;
  cursor: pointer;
}
.datetime-picker .calendar table tbody tr td:hover {
  background-color: var(--color-green);
}
.datetime-picker .calendar table tbody tr td.prev,
.datetime-picker .calendar table tbody tr td.next {
  color: #dedede;
}
.datetime-picker .calendar table tbody tr td.disabled {
  color: rgba(255, 255, 255, 0.2);
}
.datetime-picker .calendar table tbody tr td.prev:hover,
.datetime-picker .calendar table tbody tr td.next:hover {
  color: #c5c5c5;
}

.datetime-picker .calendar table tbody tr td.disabled:hover {
  color: rgba(255, 255, 255, 0.2);
  background-color: transparent;
  cursor: not-allowed;
}
.datetime-picker .calendar table tbody tr td.now {
  color: var(--color-green);
  border: 1px solid var(--color-green);
}
.datetime-picker .calendar table tbody tr td.now:hover {
  color: #fff;
  border: 0;
}
.datetime-picker .calendar table tbody tr td.selected {
  background-color: var(--color-green);
  color: #fff;
}
.datetime-picker .calendar table tbody tr td.selected:hover {
  background-color: var(--color-green);
  color: #fff;
}
.datetime-picker .calendar table tbody tr td.selected.start {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
.datetime-picker .calendar table tbody tr td.selected.end {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}
.datetime-picker .calendar table tbody tr td.selected.start.end {
  border-radius: 4px;
}
.datetime-picker .calendar table tbody tr td.between {
  background-color: #f5fbfe;
  border-radius: 0;
}
.datetime-picker .calendar .calendar-days table tbody tr td {
  width: 14.28571429%;
}
.datetime-picker .calendar .calendar-months table tbody tr td {
  width: 33.33333333%;
  height: 40px;
}
.datetime-picker .calendar .calendar-years table tbody tr td {
  width: 25%;
  height: 60px;
}
.datetime-picker .time {
  padding: 4px;
  display: flex;
  align-items: center;
  position: relative;
}
.datetime-picker .time .show-time {
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  color: #ffffff;
  min-width: 100%;
  margin-bottom: 4px;
}
.datetime-picker .time .show-time input {
  border: none;
  background: none;
  width: 34px;
  height: 34px;
  border-radius: 4px;
  font-size: 24px;
  font-family: 'Work Sans', CenturyGothic, AppleGothic, sans-serif;
  color: #fff;
  position: relative;
  z-index: 1;
  text-align: center;
}

.datetime-picker .time .show-time input:hover,
.datetime-picker .time .show-time input:focus {
  background: #2AAD5B;
}
.datetime-picker .time .show-time .separater {
  margin: 0 2px 3px;
  line-height: 1;
}
.datetime-picker .time .sliders {
  flex: 0 1 153px;
  margin-right: 10px;
  max-width: 153px;
}
.datetime-picker .time .sliders .slider-text {
  display: none;
}
.datetime-picker .time .sliders .slider {
  display: flex;
  align-items: center;
  font-size: 11px;
  height: 17px;
  background-image: -webkit-linear-gradient(left, #dedede, #dedede);
  background-image: linear-gradient(to right, #dedede, #dedede);
  background-position: 0 50%;
  background-size: 100% 1px;
  background-repeat: no-repeat;
}
.datetime-picker .time .sliders .slider .handle {
  width: 12px;
  height: 12px;
  background-color: #fff;
  border: 1px solid #dedede;
  border-radius: 3px;
  cursor: pointer;
}
.datetime-picker .time .sliders .slider .handle:hover {
  border-color: #b8b8b8;
}
.datetime-picker .time .sliders .slider .handle:focus,
.datetime-picker .time .sliders .slider .handle:active {
  background-color: var(--color-green);
  border-color: var(--color-green);
}
.datetime-picker .shortcuts-bar {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid #efefef;
  padding: 8px;
}
.datetime-picker .shortcuts-bar .btn {
  border: 1px solid transparent;
  background: none;
  cursor: pointer;
  border-radius: 2px;
  padding: 2px 4px;
  outline: none;
  display: flex;
  align-items: center;
  justify-content: center;
  font: 400 15px 'Work Sans', sans-serif;
  text-align: center;
  color: #fff;
  height: auto;
  height: initial;
  -webkit-transition: opacity 0.5s;
  transition: opacity 0.5s;
  text-decoration: none;
}
.datetime-picker .shortcuts-bar .btn:hover {
  border: 1px solid var(--color-green);
  border-radius: 5px;
  background: var(--color-green);
  color: #fff;
}
.datetime-picker .shortcuts-bar .btn:not(:first-child) {
  margin-left: 5px;
}
.datetime-range-picker {
  border: 1px solid #dbdbdb;
  background-color: #fff;
  border-radius: 4px;
  box-sizing: content-box;
  z-index: 100;
}
.datetime-trigger {
  position: relative;
}
.datetime-trigger .datetime-picker {
  position: absolute;
  top: 100%;
}
.datetime-range-trigger {
  position: relative;
}
.datetime-range-trigger .datetime-range-picker {
  position: absolute;
  top: 100%;
}
.datetime-picker-popup,
.datetime-range-picker-popup {
  margin-top: 8px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.15);
}
.datetime-picker-popup:before,
.datetime-range-picker-popup:before {
  content: '';
  position: absolute;
  background: #fff;
  border-top: 1px solid #dbdbdb;
  border-right: 1px solid #dbdbdb;
  width: 10px;
  height: 10px;
  z-index: -1;
  left: 10px;
  top: -6px;
  -webkit-transform: rotate(315deg);
          transform: rotate(315deg);
}
.datetime-picker .panel-nav {
  height: 43px;
  display: flex;
  align-items: center;
  justify-content: space-around;
  padding: 5px 0;
  border-bottom: 1px solid #efefef;
  border-left: 1px solid #efefef;
}
.datetime-picker .panel-nav + div {
  clear: both;
}
.datetime-picker .panel-nav button {
  height: 30px;
  padding: 2px 10px;
  margin: 0 10px;
  border: 1px solid var(--color-content);
  border-radius: 5px;
  background: #fff;
  color: var(--color-dark);
  cursor: pointer;
}
.datetime-picker .panel-nav button:hover,
.datetime-picker .panel-nav button.active {
  border: 1px solid var(--color-green);
  background: var(--color-green);
  color: #ffffff;
}

.className-for-animate-time-change .datetime-picker .time .sliders .slider .handle {
  -webkit-transition: all ease-in 0.25s;
  transition: all ease-in 0.25s;
}

.datetime-picker-popup:before,
.datetime-range-picker-popup:before {
  content: none;
  display: none;
}

.datetime-picker-popup:before,
.datetime-range-picker-popup:before {
  content: none;
  display: none;
}

.datetime-picker__done {
  width: 100% !important;
  height: 42px;
  line-height: 42px;
  border-radius: 0 0 4px 4px;
}

.datetime-picker--trigger-up .datetime-picker {
  top: -20px !important;
  -webkit-transform: translateY(-100%);
          transform: translateY(-100%);
}

.datetime-picker__smart_schedule {
  min-height: 68px;
  padding: 12px 27px;
  border-top: 1px solid #979797;
}

.datetime-picker__smart_schedule_desc {
  text-align: center;
  color: #fff;
  font-weight: 300;
  font-size: 13px;
  margin-bottom: 8px;
}

.datetime-picker__smart_schedule_btn {
  text-align: center;
  padding: 1px 10px;
  border-radius: 30px;
  font-size: 12px;
  color: #000;
  background-color: #D7D7D7;
}

.datetime-picker__smart_schedule_btn.active {
  background-color: var(--color-green);
  color: #fff;
}

.datetime-picker__smart_schedule .slick-slide {
  padding: 0 4px;
}

.datetime-picker__smart_schedule .slick-slider .slick-prev,
.datetime-picker__smart_schedule .slick-slider .slick-next {
  top: 50%;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
}

.datetime-picker__smart_schedule .slick-slider .slick-prev {
  left: -15px;
}

.datetime-picker__smart_schedule .slick-slider .slick-next {
  right: -15px;
  -webkit-transform: translateY(-50%) rotate(-180deg);
          transform: translateY(-50%) rotate(-180deg);
}
